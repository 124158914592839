import _omit from 'lodash/omit'
import api from './api'

/**
 * Initialize Rewards
 */
export const initRewards = () => {
  const initRewardsUrl = `/v1/${process.env.VUE_APP_DEVICE}/rewards/init`
  return api()
    .url(initRewardsUrl)
    .get()
}

export const redeemRewardBox = (selectedRewardBox, deliveryAddress) => {
  const address = _omit(deliveryAddress, ['primary', 'id'])

  const baseUrl = `/v1/${process.env.VUE_APP_DEVICE}/rewards/reward-boxes/redeem`
  return api()
    .url(baseUrl)
    .post({
      address,
      items: [
        {
          rewardBoxId: selectedRewardBox.id,
          quantity: 1,
        },
      ],
    })
}

export const redeemCart = (
  basket,
  deliveryAddress,
  dependent,
  healthActionCompletionId
) => {
  const address = _omit(deliveryAddress, [
    'primary',
    'id',
    'title',
    'name',
    'code',
  ])
  const basketForApi = basket.map((item) => ({
    rewardId: item.rewardId,
    quantity: item.quantity,
    memberCoverageId: item.memberCoverageId,
  }))

  const baseUrl = `/v2/${process.env.VUE_APP_DEVICE}/rewards/redeem`
  return api()
    .url(baseUrl)
    .post({ address, items: basketForApi, dependent, healthActionCompletionId })
}

/**
 * Setup Auto Rewards
 * @param {Number} productId - ID for selected Reward
 * @param {string} delivery - physical or digital
 * @param {Object} address - Address to be delivered if physical
 * @param {string} address.city
 * @param {string} address.streetAddr1
 * @param {string} address.streetAddr2
 * @param {string} address.state
 * @param {string} address.country
 * @param {string} address.zip
 */
export const setupAutoRewards = (productId, delivery, address) => {
  const setupUrl = `/v1/${process.env.VUE_APP_DEVICE}/rewards/setup`
  const payload = {
    productId,
    delivery,
    address: _omit(address, ['primary', 'id', 'title', 'name', 'code']),
  }
  return api()
    .url(setupUrl)
    .put(payload)
}

/**
 * Remove Auto Rewards
 * @param {Number} preferenceId - Preference ID for selected Reward
 */
export const turnOffAutoRewards = (preferenceId) => {
  const deleteUrl = `/v1/${process.env.VUE_APP_DEVICE}/rewards/${preferenceId}`

  return api()
    .url(deleteUrl)
    .delete()
}

export const getIncentiveCatalog = (incentiveCatalogCd) => {
  const getIncentiveCatalogUrl = `/v1/${process.env.VUE_APP_DEVICE}/rewards/incentive-catalog/${incentiveCatalogCd}`
  return api()
    .url(getIncentiveCatalogUrl)
    .get()
}
